<template>
  <div class="schedule-edit">
    <v-card flat tile min-height="100" color="#6290dc" class="schedule-edit-header d-flex align-center justify-center">
      <v-card tile flat max-width="400" width="100%" class="black white--text h1 py-1 text-center">Calendar</v-card>
    </v-card>
    <div class="wrap mt-n5">
      <div v-for="(item, index) in data" :key="item.day">
        <v-card class="pa-6 mb-4">
          <div class="text-capitalize h4 mb-4">{{ item.day }}</div>
          <div class="pe-7" v-for="(j, i) in item.windows" :key="i">
            <div class="h11">Slot {{ i + 1 }}</div>
            <div v-if="i != 0 || j.id" class="remove-option">
              <v-icon @click="deleteItem(i, j, item)" class="gray--text">mdi-minus</v-icon>
            </div>
            <v-row>
              <v-col cols="12" sm="6" class="pb-0">
                <v-select
                  class="field44"
                  v-model="j.begin_time"
                  :items="timeList"
                  :error-messages="fromErrors"
                  placeholder="From"
                  append-icon="mdi-chevron-down mdi-18px"
                  @change="j.end_time = ''"
                  outlined
                  menu-props="auto"
                  dense
                  color="secondary"
                  height="44"
                ></v-select>
              </v-col>
              <v-col cols="12" sm="6" class="pb-0 pt-sm-3 pt-0">
                <v-select
                  class="field44"
                  v-model="j.end_time"
                  :items="toTimeList(j.begin_time)"
                  :error-messages="toErrors"
                  :disabled="!j.begin_time"
                  placeholder="To"
                  append-icon="mdi-chevron-down mdi-18px"
                  @change="addItem(item, i)"
                  outlined
                  menu-props="auto"
                  dense
                  color="secondary"
                  height="44"
                ></v-select>
              </v-col>
            </v-row>
          </div>
          <div class="mt-2">
            <v-btn :disabled="disAdd(item.windows)" @click="newItem(index)" width="95" height="34" outlined>
              <v-icon>mdi-plus mdi-18px</v-icon> Add Slot
            </v-btn>
          </div>
        </v-card>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  metaInfo() {
    return {
      title: this.$title(`${this.$route.name}`),
    };
  },
  data() {
    return {
      timeList: [],
      error: [],
    };
  },
  mounted() {
    this.$store.dispatch('getWindowsList');
    for (let i = 9; i < 23; i++) {
      for (let j = 0; j < 6; j++) {
        this.timeList.push({ text: (i < 10 ? `0${i}` : i) + ':' + (j === 0 ? '00' : 10 * j) });
      }
    }
    this.timeList.push('23:00');
  },
  methods: {
    notifi(btn) {
      if (btn == 'errorDuration') {
        this.$store.dispatch('setMsg', 'Sorry, duration too large');
        this.$store.dispatch('setColor', 'error');
      } else if (btn == 'errorWindow') {
        this.$store.dispatch('setMsg', 'Sorry, window overlaps');
        this.$store.dispatch('setColor', 'error');
      }
    },
    disAdd(i) {
      const array = i.map((el) => el.begin_time);
      return array.includes('');
    },
    newItem(i) {
      this.data[i].windows.push({ begin_time: '', end_time: '' });
    },
    async addItem(item, i) {
      if (item.windows[i].id) {
        await this.$store.dispatch('updateWindowsList', { id: item.windows[i].id, data: item.windows[i] }).catch((e) => {
          this.error = e.response.data.error;
          if (this.error.find((item) => item == 'duration__too_large')) {
            this.notifi('errorDuration');
          } else if (this.error.find((item) => item == 'window__overlaps')) {
            this.notifi('errorWindow');
          }
        });
      } else {
        item.windows[i].day = item.day;
        await this.$store.dispatch('addWindowsList', item.windows[i]).catch((e) => {
          this.error = e.response.data.error;
          if (this.error.find((item) => item == 'duration__too_large')) {
            this.notifi('errorDuration');
          } else if (this.error.find((item) => item == 'window__overlaps')) {
            this.notifi('errorWindow');
          }
        });
      }
    },
    async deleteItem(i, data, item) {
      if (data.id) {
        await this.$store.dispatch('deleteWindowsList', data.id);
      } else {
        item.windows.splice(i, 1);
      }
    },
    toTimeList(from) {
      if (from) {
        const arr = [];
        var i = from.split(':')[0] == '09' ? '9' : from.split(':')[0];
        let j = from.split(':')[1] == '00' ? 1 : from.split(':')[1] == '50' ? 0 : +from.split(':')[1].substr(0, 1) + 1;
        if (j == 0) {
          i++;
        }
        const y = +j + 6;
        for (j; j < y; j++) {
          arr.push((j > 5 ? +i + 1 : i < 10 ? `0${i}` : i) + ':' + (j == 0 ? '00' : j < 6 ? 10 * j : j == 6 ? '00' : 10 * (j - 6)));
        }
        return arr;
      } else {
        return this.timeList;
      }
    },
  },
  watch: {
    data() {
      for (let i = 0; i < this.data.length; i++) {
        if (!this.data[i].windows.length) {
          this.data[i].windows.push({ begin_time: '', end_time: '' });
        }
      }
    },
  },
  computed: {
    data() {
      return this.$store.getters.windowsList;
    },
    fromErrors() {
      const errors = [];
      this.error.find((item) => item == 'begin_time__required') && errors.push('Please enter begin time');
      this.error.find((item) => item == 'begin_time__invalid') && errors.push('Provided begin time is not valid');
      return errors;
    },
    toErrors() {
      const errors = [];
      this.error.find((item) => item == 'end_time__required') && errors.push('Please enter end time');
      this.error.find((item) => item == 'end_time__invalid') && errors.push('Provided end time is not valid');
      return errors;
    },
  },
  destroyed() {
    this.$store.dispatch('setWindowsList', []);
  },
};
</script>

<style lang="scss">
.schedule-edit {
  &-header {
    background-image: url(../assets/img/header-img2.svg);
    background-repeat: repeat !important;
  }
  .wrap {
    max-width: 568px;
  }
  .remove-option {
    position: absolute;
    right: 20px;
    margin-top: 10px;
    .v-icon:hover {
      color: red !important;
    }
  }
}
</style>
